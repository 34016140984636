<script>
import { VclCode } from 'vue-content-loading';
import {mapGetters, mapState} from "vuex";

export default {
  components: {
    VclCode
  },
  props: {

  },
  computed: {
    ...mapGetters([
      'getPersonaName'
    ])
  },
  methods: {
    randomColor() {
      return '#'+(Math.random()*0xFFFFFF<<0).toString(16);
    },
    derivedColor(value) {
      return '#'+(value*0xFFFFFF<<0).toString(16);
    },
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/dashboard/systems', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.systems = data;

          this.serversDeployed = [data.servers.deployment.rate];
          this.serversActive = [data.servers.activity.rate];

          this.ready = true;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
    this.timer = setInterval(() => {this.getData();}, 1000 * 30);
  },
  destroyed() {
    if(this.timer) clearInterval(this.timer);
  },
  data() {
    return {
      ready: false,
      error: false,
      systems: {},
      timer: null,
      serversDeployed: [0],
      deploymentChartOptions: {
        chart: {
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: undefined,
                offsetY: 60
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: undefined,
                formatter: function(val) {
                  return val + "%";
                }
              }
            }
          }
        },
        colors: ["#556ee6"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91]
          }
        },
        stroke: {
          dashArray: 4
        },
        labels: ["Deployment Rate"]
      },
      serversActive: [],
      activityChartOptions: {
        chart: {
          offsetY: -10
        },
        plotOptions: {
          radialBar: {
            startAngle: -135,
            endAngle: 135,
            dataLabels: {
              name: {
                fontSize: "13px",
                color: undefined,
                offsetY: 60
              },
              value: {
                offsetY: 22,
                fontSize: "16px",
                color: undefined,
                formatter: function(val) {
                  return val + "%";
                }
              }
            }
          }
        },
        colors: ["#556ee6"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            shadeIntensity: 0.15,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 65, 91]
          }
        },
        stroke: {
          dashArray: 4
        },
        labels: ["Servers Active"]
      },
    }
  }
};
</script>

<template>
  <div v-if="ready">
    <div class="row h-100">
      <div class="col-lg-12 h-100">

        <div class="row">
          <div class="col-4">
            <div class="card card-body h-100">
              <div class="row">
                <div class="col-sm-4">
                  <p class="text-muted">Servers</p>
                  <h3>{{ $n(systems.servers.total) }}</h3>
                  <p class="text-muted">
                    Registered servers
                  </p>
                </div>
                <div class="col-sm-4">
                  <!-- Chart -->
                  <apexchart
                      class="apex-charts"
                      type="radialBar"
                      height="180"
                      dir="ltr"
                      :series="serversDeployed"
                      :options="deploymentChartOptions"
                  ></apexchart>
                </div>
                <div class="col-sm-4">
                  <!-- Chart -->
                  <apexchart
                      class="apex-charts"
                      type="radialBar"
                      height="180"
                      dir="ltr"
                      :series="serversActive"
                      :options="activityChartOptions"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card card-body h-100">
              <div class="row">
                <div class="col-sm-6">
                  <p class="text-muted">RCon workers</p>
                  <h3>{{ $n(systems.clients.count) }}</h3>
                  <p class="text-muted">
                    Active RCon workers
                  </p>
                </div>
                <div class="col-sm-6 align-self-center">
                  <div v-for="region in systems.clients.regions" :key="region.country_code">
                    <p class="mb-2">
                      <flag :iso="region.country_code"/>
                      {{ region.country_code }}

                    </p>
                    <h5>
                      {{ $n(region.count, {locale: 'de'}) }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="card card-body h-100">
              <div class="d-flex align-items-center mb-2">
                <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fas fa-users text-white"></i>
                    </span>
                </div>
                <h4 class="ml-3 mb-0">Total Registered Users</h4>
              </div>
              <div class="text-muted">
                <h4 class="m-0 p-0">
                  {{$n(systems.users.count, {locale: 'de'})}}
                </h4>
              </div>

	            <div class="d-flex align-items-center mt-3 mb-2">
		            <div class="avatar-xs me-3">
                    <span class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-18 avatar-sm align-self-center mini-stat-icon">
                      <i class="fas fa-gamepad-alt text-white"></i>
                    </span>
		            </div>
		            <h4 class="ml-3 mb-0">Active Play Sessions</h4>
	            </div>
	            <div class="text-muted">
		            <h4 class="m-0 p-0">
			            <img :src="'/' +gameIcon(1)" alt="dz logo" class="logo-img" height="24px" width="24px">
			            {{$n(systems.players.count, {locale: 'de'})}} / {{$n(systems.players.total, {locale: 'de'})}}
			            <small class="text-muted">
				            {{$n(systems.players.percentage, {locale: 'de'})}} %
			            </small>
		            </h4>
	            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="error">
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card border border-danger">
          <div class="card-header bg-transparent border-danger">
            <h5 class="my-0 text-danger">
              <i class="far fa-exclamation-circle mr-3"></i> {{ $t('error.server.generic.title') }}
            </h5>
          </div>
          <div class="card-body">
            <h5 class="card-title mt-0">
              {{ $t('dashboard.error.info') }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 justify-content-start">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <vcl-code :height="90"></vcl-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
